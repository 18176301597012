<template>
  <div class="h-full">
    <th-wrapper class="mt-6 ml-6 mr-6 mb-0">
      <el-descriptions :column="2" border>
        <el-descriptions-item v-for="item in items" :key="item.label">
          <template #label>
            <el-icon><component :is="item.icon" /></el-icon>
            {{ item.label }}
          </template>
          {{ item.value }}
        </el-descriptions-item>
      </el-descriptions>
    </th-wrapper>
    <!-- Table -->
    <transaction-table
      v-if="customer"
      :resources="resources"
      :customer="customer"
    />
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import { mapGetters } from 'vuex'
import { formatDateTime } from '@/utils/date'
import TransactionTable from './transaction-table'

export default {
  components: {
    TransactionTable
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      topSeller: ''
    }
  },
  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    customer() {
      return this.$route.params.id
    },
    summary() {
      return safeGet(this.modelValue, 'analytics.summary', {})
    },
    items() {
      return [
        {
          icon: 'User',
          label: this.$t('pages.reports.statistics.customers.top_seller'),
          value: this.topSeller
        },
        {
          icon: 'View',
          label: this.$t('pages.customers.analytics.last_seen'),
          value: this.summary.last_seen
            ? formatDateTime(this.summary.last_seen)
            : '-'
        },
        {
          icon: 'OfficeBuilding',
          label: this.$t('pages.reports.statistics.customers.top_branch'),
          value: this.topBranch
        },
        {
          icon: 'ShoppingCartFull',
          label: this.$t(
            'pages.customers.analytics.average_items_per_transaction'
          ),
          value: this.getFormattedValue('average_items_per_transaction')
        },
        {
          icon: 'Refresh',
          label: this.$t('pages.customers.analytics.total_amount_returns'),
          value: this.getFormattedAmount('total_amount_returns')
        },
        {
          icon: 'Money',
          label: this.$t('pages.customers.analytics.average_purchase_value'),
          value: this.getFormattedAmount('average_purchase_value')
        },
        {
          icon: 'Box',
          label: this.$t('pages.customers.analytics.total_transactions'),
          value: this.getFormattedValue('total_transactions')
        },
        {
          icon: 'Money',
          label: this.$t('pages.customers.analytics.total_amount_items_sold'),
          value: this.getFormattedAmount('total_amount_items_sold')
        }
      ]
    },
    topBranch() {
      return this.getBranchName(safeGet(this.summary, 'top_branch.0.branch'))
    }
  },
  watch: {
    summary: {
      immediate: true,
      handler: 'fetchTopSeller'
    }
  },
  methods: {
    getFormattedAmount(key) {
      const { gross = 0, currency = this.defaultCurrency } = safeGet(
        this.summary,
        `${key}.0`,
        {}
      )
      return this.$formatCurrency(gross, currency)
    },
    getFormattedValue(key, defaultValue = 0) {
      const value = safeGet(this.summary, key)
      return this.$formatNumber(value || defaultValue, {
        dynamicPrecision: true
      })
    },
    async fetchTopSeller() {
      this.topSeller = '-'
      const staffId = safeGet(this.summary, 'top_seller.0.salesman_staff')
      if (!staffId) {
        return
      }
      try {
        const { data } = await th.staff().get(staffId)
        const sellerName = this.$formatStaff(
          data,
          ['staff_number', 'fullName'],
          ' - '
        )
        this.topSeller = sellerName || '-'
      } catch (err) {
        this.$logException(err, {
          trackError: false
        })
      }
    },
    getBranchName(branchId) {
      const branch = this.resources.branches?.find(({ id }) => id === branchId)
      return branch ? this.$formatBranch(branch) : '-'
    }
  }
}
</script>
