<template>
  <div class="h-full">
    <!-- customer general information -->
    <customer-general-information
      v-if="activeTab === 'general'"
      ref="customer-general-information"
      v-model="current"
      :resources="resources"
    />
    <!-- customer analytics -->
    <customer-analytics
      v-if="activeTab === 'analytics'"
      v-model="current"
      :resources="resources"
    />
    <!-- customer notes -->
    <customer-notes
      v-if="activeTab === 'notes'"
      v-model="current"
      @note-submitted="fetchModel"
    />
    <!-- customer appointments -->
    <customer-appointments
      v-if="activeTab === 'appointments'"
      :customer="current"
    />
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import get from 'just-safe-get'
import pick from 'just-pick'
import { mapGetters } from 'vuex'
import { useCustomersStore } from '@/store/customers'
import customerModel from '../model/customer-model'
import { genInitialData } from '../helpers'
import CustomerGeneralInformation from './general'
import CustomerAnalytics from './analytics'
import CustomerNotes from './notes'
import CustomerAppointments from './appointments/index.vue'

export default {
  components: {
    CustomerGeneralInformation,
    CustomerAnalytics,
    CustomerNotes,
    CustomerAppointments
  },
  props: {
    activeTab: {
      type: String,
      required: true
    }
  },
  setup() {
    const customersStore = useCustomersStore()

    return { ...customerModel.setup(genInitialData()), customersStore }
  },
  data() {
    return {
      resources: {}
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration',
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    generateCustomerNumber() {
      return get(
        this.clientAccountConfiguration,
        'customers.generate_customer_number',
        false
      )
    }
  },
  async mounted() {
    this.id = this.$route.params.id
    await this.fetchModel()
    await this.fetchResources()
    this.sync()
  },
  methods: {
    async fetchModel() {
      return await this.fetch({ resourceOptions: { extended: true } })
    },
    async fetchResources() {
      try {
        const {
          branchesV1 = [],
          branchGroups = [],
          staff = [],
          pricebooks = []
        } = await this.$resourceFetch('branchesV1', 'branchGroups', 'staff', {
          resource: 'pricebooks',
          handler: () =>
            th
              .products()
              .pricebooks()
              .getAll({
                query: { deleted: false, type: 'customer' }
              })
        })
        this.resources = {
          branches: branchesV1,
          pricebooks,
          branchGroups,
          staff: new Map(
            staff.map((item) => [
              item.id,
              pick(item, [
                'id',
                'firstname',
                'lastname',
                'staff_number',
                'email',
                'deleted'
              ])
            ])
          )
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.settings.users.edit.form.errors.fetch.resources.code_XXX.content'
          )
        })
      }
    },
    async handleSubmit() {
      //validate the form
      const isValid = await this.validate()
      if (!isValid) return
      //save
      const isNew = this.isNew
      const createQuery = {
        query: {
          generate_customer_number: this.generateCustomerNumber
        }
      }

      const resource = this.$t('common.resource.customer.singular')
      const { error } = await this.save({ createQuery })
      if (error) {
        //save fail
        const errorMessage = isNew
          ? this.$t('common.error.action.create.single', { resource })
          : this.$t('common.error.action.update.single', { resource })

        this.$logException(error, {
          message: errorMessage,
          trackError: false
        })
        return
      }

      //save success
      const successMessage = isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })

      this.$message({
        type: 'success',
        message: successMessage
      })

      if (this.customersStore.customersCount === 0)
        this.customersStore.checkCustomersCount()

      if (isNew) {
        if (this.navigationAfterCreation === 'edit' && this.id) {
          this.routeTo(this.id)
        } else {
          this.routeTo()
        }
      } else {
        await this.fetchModel()
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.$formatCustomer(this.current)
      )
      if (confirm) this.delete()
    },
    async delete() {
      const { error } = await this.deleteModel()
      if (error) {
        //Delete failed
        const errorMessage = this.$t('common.error.action.delete.single', {
          resource: this.$t('common.resource.customer.singular')
        })

        this.$logException(error, { message: errorMessage })
        return
      }

      if (this.customersStore.customersCount === 1)
        this.customersStore.checkCustomersCount()

      //Delete success
      this.routeTo()
    },
    routeTo(path) {
      this.reset() //this will make model clean (not dirty)
      const routePath = `/customers${path ? '/' + path : ''}`
      this.$router.push(routePath)
    },
    async validate() {
      //validate customer general information
      return await this.$refs['customer-general-information']?.validate()
    }
  }
}
</script>
