<template>
  <th-wrapper :title="$t('pages.customers.form.headers.contact')">
    <el-row :gutter="20">
      <el-col :xs="24" :md="12">
        <!-- Email -->
        <el-form-item prop="email" :label="$t('common.forms.labels.email')">
          <el-input
            id="email"
            v-model="form.email"
            :placeholder="$t('common.forms.labels.email')"
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :md="12" :lg="12">
        <!-- Company name -->
        <el-form-item
          prop="company_name"
          :label="$t('common.forms.labels.company_name')"
        >
          <el-input
            id="company_name"
            v-model="companyName"
            :placeholder="$t('common.forms.labels.company_name')"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <!-- Phone numbers -->
    <phone-inputs
      ref="customer-contact-phones"
      v-model="form.phonenumbers"
      class="mb-4"
      :phone-types="phoneTypes"
    />

    <!-- Addresses -->
    <customer-contact-address
      ref="customer-contact-address"
      v-model="form.addresses"
    />
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import PhoneInputs from '@/components/contact-details/phones'
import CustomerContactAddress from './customer-contact-address'
import { CUSTOMERS_PHONE_TYPES } from '@/constants'

export default {
  components: { PhoneInputs, CustomerContactAddress },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    companyName: {
      get() {
        return get(this.form, 'company.name', '')
      },
      set(value) {
        const temp = this.form
        temp.company = { ...temp.company, name: value }
        this.form = temp
      }
    },
    phoneTypes() {
      return Object.values(CUSTOMERS_PHONE_TYPES)
    }
  },
  methods: {
    handleSubmit() {
      this.form = this.$refs['customer-contact-phones'].transformData()
    },
    async validate() {
      return this.$refs['customer-contact-phones'].validate()
    }
  }
}
</script>
