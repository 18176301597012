export const defaultDiscount = {
  type: 'percentage',
  amount: 0,
  group: 'customer'
}

export const phoneTypes = ['main', 'home', 'work', 'mobile']

export const addressTypes = ['billing', 'delivery']

export function genInitialPhone() {
  return { phone_type: null, phone_number: null }
}

export const attributesToOmit = ['analytics', 'notes']

export function genInitialData() {
  return {
    gender: null,
    firstname: null,
    middlename: null,
    lastname: null,
    date_of_birth: null,
    email: null,
    company: {
      name: null
    },
    addresses: [],
    phonenumbers: [], //server expect object, data will be mutated to object before save
    description: null,
    customer_number: null,
    customer_since: null,
    price_book: null,
    is_b2b: false,
    active: true,
    contacts: {
      newsletter: {
        enabled: true
      }
    },
    image: {},
    discounts: [
      {
        ...defaultDiscount
      }
    ],
    locations: null,
    branch_groups: null,
    comment: null,
    analytics: null,
    notes: null,
    source: 'DASHBOARD',
    branch_id: null
  }
}

export function genInitialNoteData() {
  return {
    type: null,
    payload: null
  }
}
