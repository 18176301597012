<template>
  <th-wrapper :title="$t('common.titles.general_info.title')">
    <template #center>
      <div class="flex">
        <el-checkbox v-model="form.active" class="mb-4">
          {{ $t('common.forms.labels.active') }}
        </el-checkbox>
        <el-checkbox v-model="newsletter" class="mb-4">
          {{ $t('pages.customers.form.labels.newsletter') }}
        </el-checkbox>
        <el-checkbox v-model="form.is_b2b" class="mb-4">
          {{ $t('pages.customers.form.labels.b2b') }}
        </el-checkbox>
      </div>
    </template>
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="10">
        <el-col :md="12">
          <!-- First name -->
          <el-col>
            <el-form-item
              prop="firstname"
              :label="$t('common.forms.labels.firstname')"
            >
              <el-input
                id="firstname"
                v-model="form.firstname"
                :placeholder="$t('common.forms.labels.firstname')"
              />
            </el-form-item>
          </el-col>
          <!-- Last name -->
          <el-col>
            <el-form-item
              prop="lastname"
              :label="$t('common.forms.labels.lastname')"
            >
              <el-input
                id="lastname"
                v-model="form.lastname"
                :placeholder="$t('common.forms.labels.lastname')"
              />
            </el-form-item>
          </el-col>
          <el-row :gutter="10">
            <!-- Data of birth -->
            <el-col :md="12">
              <el-form-item
                prop="date_of_birth"
                :label="$t('common.forms.labels.date_of_birth')"
                class="w-full"
              >
                <date-picker-input
                  v-model="form.date_of_birth"
                  class="w-full"
                  no-future-dates
                  :placeholder="$t('pages.staff.form.placeholder.select_date')"
                />
              </el-form-item>
            </el-col>
            <!-- Price book -->
            <el-col :md="12">
              <el-form-item
                prop="price_book"
                :label="$t('common.resource.pricebook.singular')"
              >
                <el-select
                  id="price_book"
                  v-model="form.price_book"
                  clearable
                  class="w-full"
                  :popper-append-to-body="false"
                  :placeholder="$t('common.inputs.placeholders.select')"
                  @clear="form.price_book = null"
                >
                  <el-option
                    v-for="pricebook in pricebooks"
                    :key="pricebook.value"
                    :value="pricebook.value"
                    :label="pricebook.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- Discount -->
          <el-col :md="12">
            <el-form-item
              prop="discount"
              :label="$t('pages.discounts.edit.form.properties.discount.label')"
            >
              <th-number-input
                id="discount"
                v-model="discount"
                :locale="$i18n.locale"
                :placeholder="
                  $t('pages.products.edit.form.properties.discount.label')
                "
                append-text="%"
              />
            </el-form-item>
          </el-col>
          <!-- Available in -->
          <el-col>
            <el-form-item
              for="locations"
              :label="$t('common.forms.labels.locations')"
            >
              <available-in
                id="locations"
                :model-value="{
                  locations: form.locations,
                  branch_groups: form.branch_groups
                }"
                :resources="resources"
                :show-items-limit="2"
                @update:modelValue="handleAvailableInInput"
              />
            </el-form-item>
          </el-col>

          <!-- First seen -->
          <!-- <el-col>
            <el-form-item
              prop="branch_id"
              :label="$t('pages.customers.form.labels.first_seen')"
            >
              <el-select
                id="branch_id"
                v-model="form.branch_id"
                clearable
                class="w-full"
                :popper-append-to-body="false"
                :placeholder="$t('common.inputs.placeholders.select')"
                @clear="form.branch_id = null"
              >
                <el-option
                  v-for="branch in resources.branches"
                  :key="branch.id"
                  :value="branch.id"
                  :label="branch.name"
                />
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-col>
        <el-col :md="12">
          <el-row :gutter="10">
            <el-col :md="15">
              <!-- Gender -->
              <el-col>
                <el-form-item
                  prop="gender"
                  :label="$t('common.forms.labels.gender')"
                >
                  <gender-select id="gender" v-model="form.gender" />
                </el-form-item>
              </el-col>
              <!-- Customer number -->
              <el-col>
                <el-form-item
                  prop="customer_number"
                  :label="
                    $t('pages.customers.form.placeholder.customer_number')
                  "
                >
                  <el-input
                    id="customer_number"
                    v-model="form.customer_number"
                    :disabled="generateCustomerNumber"
                    :placeholder="
                      generateCustomerNumber
                        ? $t(
                            'pages.customers.form.hint.customer_number.is_auto'
                          )
                        : $t('pages.customers.form.placeholder.customer_number')
                    "
                  />
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :md="9">
              <!-- Customer image -->
              <el-form-item
                :label="$t('common.forms.labels.image')"
                prop="image"
              >
                <th-image-upload-v2
                  id="image"
                  v-model="form.image"
                  style="height: 123px"
                  resource="customers"
                  image-key="1x"
                  fill-type="contain"
                  icon="PictureRounded"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- Comment -->
          <el-col>
            <el-form-item
              prop="comment"
              :label="$t('pages.customers.form.placeholder.comment')"
            >
              <el-input
                id="comment"
                v-model="form.comment"
                type="textarea"
                :rows="3"
                :placeholder="$t('pages.customers.form.placeholder.comment')"
              />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import set from 'just-safe-set'
import { mapGetters } from 'vuex'
import AvailableIn from '@/components/available-in'
import DatePickerInput from '@/components/inputs/date-picker'
import GenderSelect from '@/components/select/gender-select'
import { defaultDiscount } from '../../helpers'

export default {
  components: {
    AvailableIn,
    DatePickerInput,
    GenderSelect
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    generateCustomerNumber() {
      return !!get(
        this.clientAccountConfiguration,
        'customers.generate_customer_number'
      )
    },
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    newsletter: {
      get() {
        return get(this.form, 'contacts.newsletter.enabled', false)
      },
      set(value) {
        const temp = this.form
        set(this.form, 'contacts.newsletter.enabled', value)
        this.form = temp
      }
    },
    discount: {
      get() {
        return get(this.form, 'discounts.0.amount', 0)
      },
      set(value) {
        const temp = this.form
        // Initilizate in case it was set to null and lost the base structure
        if (!Array.isArray(this.form.discounts)) {
          this.form.discounts = [{ ...defaultDiscount }]
        }
        this.form.discounts[0].amount = value
        this.form = temp
      }
    },
    rules() {
      return {
        customer_number: [
          {
            required: !this.generateCustomerNumber,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ]
      }
    },
    pricebooks() {
      return this.resources.pricebooks?.map((pricebook) => ({
        value: pricebook.id,
        label: pricebook.name
      }))
    }
  },
  methods: {
    handleAvailableInInput({ locations, branch_groups }) {
      this.form.locations = locations
      this.form.branch_groups = branch_groups
    },
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
