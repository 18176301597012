<template>
  <div class="h-full">
    <th-drawer ref="edit" v-model="isModalOpen" width="500px">
      <receipt-viewer
        v-if="currentRow.id"
        :key="currentRow.id"
        :receipts="currentRow.receipts"
        :receipt-title="currentRow.id"
        :resource-id="currentRow.id"
        @close-receipts="closeReceiptViewer"
        @cancel-requested="closeReceiptViewer"
      />
    </th-drawer>

    <th-drawer :open="isImageViewerOpened" @update="toggleOpen($event)">
      <image-viewer
        v-if="currentRow.id"
        :key="currentRow.id"
        :transaction="currentRow.transaction"
        @close-images="toggleOpen(false)"
      />
    </th-drawer>
    <th-datatable
      ref="table"
      class="p-0"
      block-no-data
      expanding-row
      no-meta-check
      resource="transactions"
      transform-fetched-meta-allowed
      :custom-resource="txResource()"
      :headers="headers"
      :locale="locale"
      :paging="false"
      :resource-limit="100"
      :resource-query="resourceQuery"
      :show-operations="false"
      :show-summary="false"
      :show-tool-header="false"
      :transform-fetched-data="transformFetchedData"
      @loading-error="handleLoadingError"
    >
      <template #expanding-row="{ row }">
        <expanding-row
          :row="row"
          @open-current-receipts="handleOpenReceipts"
          @open-current-images="handleOpenImages"
        />
      </template>
    </th-datatable>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import ExpandingRow from '@/views/reports/financial-accounting/transactions/components/expanding-row'
import ReceiptViewer from '@/components/tillhub/receipt-viewer'
import ImageViewer from '@/views/reports/financial-accounting/transactions/components/image-viewer'
import { waitForData } from '@/utils/general'

export default {
  components: {
    ExpandingRow,
    ReceiptViewer,
    ImageViewer
  },
  props: {
    customer: {
      type: String,
      default: undefined
    },
    resources: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isModalOpen: false,
      isImageViewerOpened: false,
      currentRow: {
        receipts: [],
        id: null
      }
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      timeZone: 'Config/getTimeZone',
      locale: 'Config/getLocale'
    }),
    headers() {
      return [
        {
          field: '_date_label',
          label: this.$t('pages.transactions.all.table.date'),
          fallback: '-',
          width: 150,
          truncate: true,
          sortType: 'date'
        },
        {
          field: 'custom_id',
          label: this.$t('pages.transactions.all.table.receipt_number'),
          fallback: '-',
          width: 150,
          truncate: true,
          sortType: 'number'
        },
        {
          field: '_staff_label',
          label: this.$t('pages.transactions.all.table.staff'),
          fallback: '-',
          width: 150,
          truncate: true
        },
        {
          field: 'branch_custom_id',
          label: this.$t('pages.transactions.all.table.branch_custom_id'),
          minWidth: 100,
          fallback: '-',
          truncate: true,
          sortType: 'number'
        },
        {
          field: 'register_custom_id',
          label: this.$t('pages.transactions.all.table.register_custom_id'),
          minWidth: 120,
          fallback: '-',
          truncate: true
        },
        {
          field: 'balance_custom_id',
          label: this.$t('pages.transactions.all.table.balance_number'),
          minWidth: 100,
          fallback: '-',
          truncate: true
        },
        {
          field: 'total',
          label: this.$t('pages.transactions.all.table.amount_total_gross'),
          fallback: '-',
          minWidth: 100,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.total)) {
              return this.$formatCurrency(row.total, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: '_type_label',
          label: this.$t('common.headers.type.title'),
          minWidth: 100,
          fallback: '-',
          truncate: true
        }
      ]
    },
    resourceQuery() {
      return {
        query: {
          customer: this.customer,
          limit: 100
        }
      }
    },
    registers() {
      const { registers } = this.resources
      if (!registers) return
      return Array.from(registers || [])
        .filter(([, r]) => r.deleted !== true)
        .filter(
          ([, r]) => !this.currentLocation || r.branch === this.currentLocation
        )
        .filter(([, r]) => Number.isFinite(r.register_number)) // needed to prevent odd default behavior
        .map(([, r]) => ({
          value: r.register_number,
          label: this.$formatRegister(r),
          key: r.id
        }))
    },
    translations() {
      return {
        deposit: this.$t('common.data.financials.types.deposit.label'),
        expense: this.$t('common.data.financials.types.expense.label'),
        sale: this.$t('common.data.financials.types.sale.label'),
        sale_cancel: this.$t('common.data.financials.types.sale_cancel.label'),
        bank: this.$t('common.data.financials.secondaries.types.bank.label'),
        deposit_cancel: this.$t(
          'common.data.financials.secondaries.types.deposit_cancel.label'
        ),
        expense_cancel: this.$t(
          'common.data.financials.secondaries.types.expense_cancel.label'
        ),
        refund: this.$t(
          'common.data.financials.secondaries.types.refund.label'
        ),
        safe: this.$t('common.data.financials.secondaries.types.safe.label'),
        safes: this.$t('common.data.financials.secondaries.types.safes.label'),
        safe_deposit: this.$t(
          'common.data.financials.secondaries.types.safe_deposit.label'
        ),
        safe_deposit_cancel: this.$t(
          'common.data.financials.secondaries.types.safe_deposit_cancel.label'
        ),
        safe_expense: this.$t(
          'common.data.financials.secondaries.types.safe_expense.label'
        ),
        tip: this.$t('common.data.financials.secondaries.types.tip.label'),
        tip_expense: this.$t(
          'common.data.financials.secondaries.types.tip_expense.label'
        )
      }
    }
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.transactions.title')
        })
      })
    },
    handleOpenReceipts(row) {
      this.currentRow.id = row.resourceId
      this.currentRow.receipts = row.receipts
      this.isModalOpen = true
    },
    closeReceiptViewer() {
      this.isModalOpen = false
    },
    handleOpenImages({ resourceId, transaction }) {
      this.currentRow.id = resourceId
      this.currentRow.transaction = transaction
      this.toggleOpen(!this.isImageViewerOpened)
    },
    txResource() {
      return th.analyticsHandlersV3({ timeout: 120000 }).analytics.reports
        .AnalyticsReportsTransactions
    },
    // NOTE: this is a perfomance optmisation in order not to parse in formatters, which seems to be costly in massive data scenarios.
    // The gist is: pre-digest strings, so the call stacks get thinner later. This mutates actual data inside the table
    async transformFetchedData(data) {
      await waitForData(() => this.resources.staff)
      return data.map((item) => {
        const staff = this.resources.staff.get(item.cashier_staff)
        if (staff) {
          item._staff_label = this.$formatStaff(
            staff,
            ['staff_number', 'fullName'],
            ' - '
          )
        }
        // New types were added for DAS-1785
        if (!item.additional_type && !item.type) {
          item._type_label = '-'
        } else if (
          item.type === 'sale' &&
          !item.additional_type &&
          item.total < 0
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.refund.label`
          )
        } else if (
          item.type === 'expense' &&
          item.additional_type === 'expense'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.expense.label`
          )
        } else if (
          item.type === 'expense_cancel' &&
          item.additional_type === 'expense'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.expense_cancel.label`
          )
        } else if (
          item.type === 'expense' &&
          item.additional_type === 'deposit'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.deposit.label`
          )
        } else if (
          item.type === 'expense_cancel' &&
          item.additional_type === 'deposit'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.deposit_cancel.label`
          )
        } else if (
          item.type === 'expense' &&
          item.additional_type === 'safe_expense'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.safe_deposit.label`
          )
        } else if (
          item.type === 'expense_cancel' &&
          item.additional_type === 'safe_expense'
        ) {
          item._type_label = this.$t(
            `common.data.financials.secondaries.types.safe_deposit_cancel.label`
          )
        } else {
          item._type_label = this.translations[
            item.additional_type || item.type
          ]
        }

        if (!item.date) {
          item._date_label = '-'
          item._date_local_label = '-'
        } else {
          item._date_label = this.$date.formatDateTimeWithTimezone(
            item.date,
            item.timezone
          )
          item._date_local_label = this.$date.formatDateTimeWithTimezone(
            item.date
          )
        }

        return item
      })
    },
    toggleOpen(isOpen) {
      this.isImageViewerOpened = isOpen
    }
  }
}
</script>

<style scoped></style>
