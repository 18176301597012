<template>
  <div class="h-full flex flex-col">
    <el-card
      :body-style="{
        padding: '0px',
        height: '100%'
      }"
      class="h-full flex-1"
    >
      <el-table
        v-loading="loading"
        :data="appointments"
        class="w-full"
        max-height="100%"
        height="100%"
      >
        <el-table-column
          :label="t('pages.customers.appointments.table.services')"
          prop="_service_names"
          min-width="250px"
          :show-overflow-tooltip="true"
        />

        <el-table-column
          :label="t('common.headers.date.title')"
          min-width="200px"
          :formatter="
            (appointment) => formatDateTimeWithTimezone(appointment.start)
          "
          :show-overflow-tooltip="true"
        />

        <el-table-column
          :label="t('pages.customers.appointments.table.staff')"
          min-width="250"
          prop="_staff_names"
          :show-overflow-tooltip="true"
        />

        <el-table-column
          :label="t('pages.customers.appointments.table.canceled.label')"
          min-width="250"
          prop="_canceled_label"
          :show-overflow-tooltip="true"
        />

        <!-- <el-table-column
          :label="t('pages.customers.appointments.table.branch')"
          min-width="200"
          prop="_branch_label"
          :show-overflow-tooltip="true"
        /> -->
      </el-table>
    </el-card>
  </div>
</template>

<script setup>
import { defineProps, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { waitForData } from '@/utils/general'
import { formatStaffMemberName } from './helpers'
import { formatDateTimeWithTimezone } from '@/utils/date'
import th from '@tillhub/javascript-sdk'

const props = defineProps({
  customerId: {
    type: String,
    required: true
  },
  resources: {
    type: Object,
    required: true
  },
  resourceQuery: {
    type: Object,
    required: true
  },
  handleLoadingError: {
    type: Function,
    required: true
  }
})

const { t } = useI18n()
const loading = ref(true)
const appointments = ref([])

async function fetch() {
  try {
    loading.value = true

    const { data } = await th
      .customerAppointments()
      .getAll(props.customerId, props.resourceQuery)

    appointments.value = await transformFetchedData(data)
  } catch (err) {
    props.handleLoadingError(err)
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await fetch()
})

async function transformFetchedData(appointments) {
  const { resources } = props

  await waitForData(() => resources.staff && resources.branches)

  appointments.forEach((appointment) => {
    appointment._branch_label =
      resources.branches.find((branch) => branch.id === appointment.branch_id)
        ?.name || '-'

    appointment._staff_names = appointment.lineItems
      .map((lineItem) => {
        const staff = resources.staff.find(
          (staffMember) => staffMember.id === lineItem.staffId
        )

        return staff ? formatStaffMemberName(staff) : ''
      })
      .join(', ')

    appointment._service_names = appointment.lineItems
      .map((lineItem) => lineItem.serviceName)
      .join(', ')

    const { status } = appointment

    let canceledLabel = '-'

    if (status === 'CANCELED') {
      canceledLabel = t(
        'pages.customers.appointments.table.canceled.by_customer'
      )
    } else if (status === 'DECLINED_BY_MERCHANT') {
      canceledLabel = t('pages.customers.appointments.table.canceled.by_staff')
    } else if (status === 'NO_SHOW') {
      canceledLabel = t(
        'pages.customers.appointments.table.canceled.customer_did_not_arrive'
      )
    }

    appointment._canceled_label = canceledLabel
  })

  return appointments
}
</script>

<style scoped>
.el-table :deep(.el-table__cell) {
  padding-left: 0;
  padding-right: 0;
}
.el-table :deep(.el-table__expanded-cell) {
  background: #ffffff;
  border-left: 3px solid var(--primary-color);
}

.el-table :deep(.el-table__expanded-cell:hover) {
  background: #f5f7fa !important;
}

.el-table :deep(.el-table__row.expanded td:first-child) {
  border-left: 3px solid var(--primary-color);
}

.el-table :deep(th .cell) {
  white-space: nowrap;
  color: var(--el-table-text-color-deep);
  letter-spacing: var(--letter-spacing-table);
}

.el-table :deep(td .cell) {
  font-size: var(--el-table-text-sm);
  color: var(--el-table-text-color-deep);
  letter-spacing: var(--letter-spacing-table);
  min-height: 22px;
}

.el-table__body-wrapper {
  overflow: auto;
}

.no-data-default {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
