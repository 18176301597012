<template>
  <div class="flex items-center m-6 mb-0" data-testid="product-type-selector">
    <div
      v-for="(name, key) of tabs"
      :key="key"
      class="item"
      :class="{ active: activeTab === key }"
    >
      <input
        :id="key"
        v-model="activeTab"
        type="radio"
        name="product-type"
        class="hidden"
        :value="key"
      />
      <label
        :for="key"
        class="block cursor-pointer px-4 py-1 text-sm border-transparent"
      >
        {{ name }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: 'general'
    }
  },

  computed: {
    tabs() {
      return {
        general: this.$t('pages.customers.form.tabs.general'),
        analytics: this.$t('pages.customers.form.tabs.analytics'),
        notes: this.$t('pages.customers.form.tabs.notes'),
        ...(this.$isFeatureEnabled('reservations')
          ? {
              appointments: this.$t('pages.customers.form.tabs.appointments')
            }
          : {})
      }
    },
    activeTab: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style scoped>
.item label {
  border-bottom-width: 3px;
  letter-spacing: var(--letter-spacing-titles);
}

.item.active label {
  font-weight: bold;
  color: var(--primary-color);
  border-color: var(--primary-color) !important;
  line-height: var(--el-line-height);
}
</style>
