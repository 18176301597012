<template>
  <el-form
    ref="form"
    v-loading="model.loading"
    :validate-on-rule-change="false"
    class="p-6"
  >
    <!-- customer information -->
    <customer-information
      ref="customer-information"
      v-model="model"
      :resources="resources"
    />

    <!-- customer address -->
    <customer-contact ref="customer-address" v-model="model" />
  </el-form>
</template>

<script>
import get from 'just-safe-get'
import { mapGetters } from 'vuex'
import CustomerInformation from './customer-information'
import CustomerContact from './customer-contact'

export default {
  components: { CustomerInformation, CustomerContact },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeTab: 'general'
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration',
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    generateCustomerNumber() {
      return get(
        this.clientAccountConfiguration,
        'customers.generate_customer_number',
        false
      )
    }
  },
  methods: {
    async validate() {
      //validate customer information
      return await this.$refs['customer-information'].validate()
    }
  }
}
</script>
