<template>
  <th-page-wrapper
    class="text-th-primary h-full"
    align-title="center"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <!-- customer tabs options -->
    <customer-tab-selector v-if="!isNew" v-model="activeTab" />
    <customer-form ref="form" :active-tab="activeTab" />
  </th-page-wrapper>
</template>

<script>
import CustomerTabSelector from './components/customer-tab-selector'
import CustomerForm from './components/form'

export default {
  components: { CustomerTabSelector, CustomerForm },
  data() {
    return {
      activeTab: 'general'
    }
  },
  computed: {
    isNew() {
      const id = this.$route.params.id
      return !id || id === 'new'
    },
    actions() {
      return {
        excludeButtons: this.activeTab === 'general' ? [] : ['save'],
        permissionPrefix: 'customers',
        returnedPath: { name: 'customers-list' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.handleSubmit()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>
