import baseModel from '@/models/base-model-new'

export default {
  setup(model) {
    const methods = {
      updateMethod() {
        return 'createNote'
      },
      parseDataBeforeSave(data) {
        return { ...data, payload: data.payload.trim() }
      }
    }
    return baseModel.setup(model, 'customers', methods)
  }
}
