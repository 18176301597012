<template>
  <div class="h-full">
    <th-wrapper
      v-loading="loading"
      :title="$t('pages.customers.appointments.future.title')"
      class="m-6"
      body-class="p-0"
    >
      <div class="appointment-table-wrapper p-6">
        <appointments-table
          :customer-id="$route.params.id"
          :resources="resources"
          :resource-query="{
            query: { deleted: false, when: 'future' },
            limit: 100
          }"
          :handle-loading-error="
            (err) => {
              logException(err, {
                trackError: false,
                message: t('common.error.action.read.multiple', {
                  resources: t('pages.customers.appointments.future.full_title')
                })
              })
            }
          "
        />
      </div>
    </th-wrapper>

    <th-wrapper
      v-loading="loading"
      class="m-6"
      body-class="p-0"
      :title="$t('pages.customers.appointments.past.title')"
      :subtitle="
        $t('pages.customers.appointments.past.description', {
          count: 30
        })
      "
    >
      <div class="appointment-table-wrapper p-6">
        <appointments-table
          :customer-id="$route.params.id"
          :resources="resources"
          :handle-loading-error="
            (err) => {
              logException(err, {
                trackError: false,
                message: t('common.error.action.read.multiple', {
                  resources: t('pages.customers.appointments.past.full_title')
                })
              })
            }
          "
          :resource-query="{
            query: { deleted: false, when: 'past' },
            limit: 30
          }"
        />
      </div>
    </th-wrapper>
  </div>
</template>

<script setup>
import AppointmentsTable from './appointments-table.vue'
import { defineProps, inject, ref, onMounted } from 'vue'

const props = defineProps({
  customer: {
    type: Object,
    required: true
  }
})

const resourceFetch = inject('resourceFetch')
const logException = inject('logException')

const resources = ref({})
const loading = ref(false)

onMounted(async () => {
  await fetchResources()
})

async function fetchResources() {
  try {
    loading.value = true

    const { staff, branchesV1 } = await resourceFetch('staff', 'branchesV1')

    resources.value = {
      staff,
      branches: branchesV1
    }
  } catch (err) {
    logException(err, {
      trackError: false
    })
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>
.appointment-table-wrapper {
  height: 40vh;
}
</style>
