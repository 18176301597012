import { defineStore } from 'pinia'
import th from '@tillhub/javascript-sdk'

export const useCustomersStore = defineStore('customers', {
  state: () => ({ customersCount: 1 }),
  getters: {
    customersExists: (state) => state.customersCount > 0
  },
  actions: {
    async checkCustomersCount() {
      try {
        const { data } = await th
          .customersV1()
          .meta({ query: { deleted: false } })
        this.customersCount = data?.count || 0
      } catch (err) {
        this.$logException(err, { trackError: false })
        this.customersCount = 1
      }
    }
  }
})
