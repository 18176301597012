<template>
  <el-select
    :id="id"
    v-model="localValue"
    class="w-full"
    :popper-append-to-body="false"
  >
    <el-option
      v-for="gender in genderList"
      :key="gender.value"
      :label="gender.label"
      :value="gender.value"
    />
  </el-select>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: 'gender'
    },
    id: {
      type: String,
      default: 'gender'
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    genderList() {
      return [
        {
          value: 'female',
          label: this.$t(
            'pages.customers.form.personal_information.gender.female'
          )
        },
        {
          value: 'male',
          label: this.$t(
            'pages.customers.form.personal_information.gender.male'
          )
        },
        {
          value: 'unspecified',
          label: this.$t(
            'pages.customers.form.personal_information.gender.unspecified'
          )
        }
      ]
    }
  }
}
</script>
