import baseModel from '@/models/base-model-new'
import { CUSTOMERS_PHONE_TYPES } from '@/constants'
import { genInitialAddress } from '@/utils/address'
import { attributesToOmit } from '../helpers'
import omit from 'just-omit'

export default {
  setup(model) {
    const methods = {
      parseResponse(response) {
        //transform phonenumbers from object to array
        const data = response?.data || {}
        const phonenumbers = Object.values(CUSTOMERS_PHONE_TYPES).reduce(
          (acc, phone_type) => {
            if (data.phonenumbers?.[phone_type]) {
              acc.push({
                phone_type,
                phone_number: data.phonenumbers[phone_type]
              })
            }
            return acc
          },
          []
        )

        //initiate newsletter object
        if (!data.contacts?.newsletter) {
          data.contacts = { newsletter: { enabled: false } }
        }

        //initial addresses if null or empty
        if (!data?.addresses?.length) {
          data.addresses = [{ ...genInitialAddress(), type: 'billing' }]
        }

        return { ...data, phonenumbers }
      },
      parseDataBeforeSave(data) {
        //transform phonenumbers from object to array before saving
        const phonenumbers = data.phonenumbers.reduce(
          (acc, { phone_type, phone_number }) => {
            if (phone_type && phone_number) {
              acc[phone_type] = phone_number
            }
            return acc
          },
          {}
        )

        // if no discounts, set discounts to null before saving
        const amount = data.discounts?.[0]?.amount
        if (!amount) {
          data.discounts = null
        }

        //if phonenumbers is an empty object send null
        //if we set the discount amount to null, then we don't want to send the array with the object, we send just null
        return {
          ...omit(data, attributesToOmit),
          phonenumbers: Object.keys(phonenumbers).length ? phonenumbers : null
        }
      }
    }
    return baseModel.setup(model, 'customersV1', methods)
  }
}
