<template>
  <th-wrapper class="m-6">
    <el-row :gutter="20">
      <el-col :xs="20" :md="9">
        <!-- customer image -->
        <div v-if="customerImage" class="p-4 flex justify-center h-64">
          <el-image
            v-if="customerImage"
            fit="scale-down"
            :src="customerImage"
          />
        </div>
        <!-- customer information -->
        <el-descriptions :column="1" border>
          <el-descriptions-item
            v-for="(item, index) in customerInformation"
            :key="index"
            :label="item.label"
          >
            <p
              v-for="(text, textIndex) in splitLines(item.value, ',')"
              :key="textIndex"
              class="mt-0 mb-0"
              v-text="text"
            />
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :xs="20" :md="15">
        <!-- create note -->
        <el-form
          ref="form"
          :model="current"
          :rules="rules"
          class="flex items-center p-2"
        >
          <el-form-item
            prop="payload"
            class="flex-grow"
            :label="$t('pages.customers.notes.note')"
          >
            <el-input
              id="payload"
              v-model="current.payload"
              type="textarea"
              maxlength="16384"
              :rows="2"
            />
          </el-form-item>
          <!-- create note button -->
          <el-button
            type="primary"
            class="m-2"
            data-testid="note-create-button"
            @click="submitForm"
            v-text="$t('common.interactions.buttons.create')"
          />
        </el-form>

        <!-- Notes timeline -->
        <div class="overflow-auto notes p-2">
          <el-timeline>
            <el-timeline-item
              v-for="(note, index) in notes"
              :key="index"
              placement="top"
              :timestamp="$date.formatDateTime(note.created_at)"
            >
              <el-card class="pr-6">
                <p
                  v-for="(text, textIndex) in splitLines(note.payload, '\n')"
                  :key="textIndex"
                  class="break-all"
                  v-text="text"
                />
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-col>
    </el-row>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import { formatAddress } from '@/utils/address'
import customerNoteModel from '../../model/customer-note-model'
import { genInitialNoteData } from '../../helpers'

export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup() {
    return customerNoteModel.setup(genInitialNoteData())
  },
  data() {
    return {
      rules: {
        payload: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          },
          { validator: this.validateEmptyString, trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    customerModel() {
      return this.modelValue
    },
    notes() {
      return [...(this.customerModel?.notes || [])].reverse()
    },
    customerInformation() {
      return [
        {
          label: this.$t('pages.discounts.edit.form.properties.name.label'),
          value: this.$formatCustomer(this.customerModel)
        },
        {
          label: this.$t('pages.customers.edit.form.properties.addresses.add'),
          value: formatAddress(safeGet(this.customerModel, 'addresses.0', ''))
        },
        {
          label: this.$t('common.forms.labels.email'),
          value: this.customerModel.email
        },
        {
          label: this.$t('common.forms.labels.phonenumber'),
          value: safeGet(this.customerModel, 'phonenumbers.0.phone_number', '')
        },
        {
          label: this.$t('pages.customers.form.placeholder.customer_number'),
          value: this.customerModel.customer_number
        },
        {
          label: this.$t('common.forms.labels.company_name'),
          value: this.customerModel.company?.name || ''
        },
        {
          label: this.$t('pages.customers.form.labels.b2b'),
          value: this.yesNoTranslations(this.customerModel.is_b2b)
        },
        {
          label: this.$t('pages.customers.form.labels.newsletter'),
          value: this.yesNoTranslations(
            safeGet(this.customerModel, 'contacts.newsletter.enabled')
          )
        },
        {
          label: this.$t('pages.products.edit.form.properties.discount.label'),
          value: `${this.$formatNumber(
            safeGet(this.customerModel, 'discounts.0.amount', 0),
            { dynamicPrecision: true }
          )} %`
        }
      ]
    },
    customerImage() {
      return safeGet(this.customerModel, 'image.2x', null)
    }
  },
  methods: {
    splitLines(line, separator) {
      return line?.split(separator) || []
    },
    yesNoTranslations(isActive) {
      return isActive
        ? this.$t('common.interactions.buttons.yes')
        : this.$t('common.interactions.buttons.no')
    },
    validateEmptyString(_, value, callback) {
      if (!value.trim()) {
        callback(
          new Error(this.$t('common.forms.rules.field_warnings.required'))
        )
      } else {
        callback()
      }
    },
    async submitForm() {
      const isValid = await this.validate()
      if (!isValid) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'pages.processes.edit.form.warnings.invalid_inputs.contents'
          )
        })
      }

      this.id = this.$route.params.id //add the customer id
      this.current.type = 'text' //add type

      const { error } = await this.save()

      const resource = this.$t('pages.customers.notes.note')
      //handle error
      if (error) {
        const errorMessage = this.$t('common.error.action.create.single', {
          resource
        })
        return this.$logException(error, {
          trackError: true,
          message: errorMessage
        })
      }

      //handle success
      const successMessage = this.$t('common.success.action.create.single', {
        resource
      })
      this.$message({
        type: 'success',
        message: successMessage
      })

      this.$emit('note-submitted')
      this.reset()
    },
    validate() {
      return new Promise((resolve) => this.$refs.form.validate(resolve))
    },
    reset() {
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style scoped>
.notes {
  height: 70vh;
}
</style>
